import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices';
let mWorkPlatFormHomeTodo = class mWorkPlatFormHomeTodo extends Vue {
    constructor() {
        super(...arguments);
        this.list = [];
    }
    /**
     * 获取首页待办
     */
    getTodoList() {
        const params = {
            todoType: '1',
            page: 1,
            size: 3,
        };
        return new Promise((resolve, reject) => {
            return WorkPlatformService.getTodoList(params).then((res) => {
                if (res.success) {
                    this.list = res.data;
                    this.$emit('triggerGetListLength', this.list.length);
                    resolve(res);
                }
            }).catch((e) => {
                reject(e);
            });
        });
    }
    /**
     * 如果流程发生变化，则刷新数据
     */
    reloadPage(evt) {
        if (evt.data === 'reload' && evt.origin === window.location.origin) {
            this.getTodoList();
        }
    }
    created() {
        window.addEventListener('message', this.reloadPage, false);
    }
};
mWorkPlatFormHomeTodo = __decorate([
    Component({
        name: 'mWorkPlatFormHomeTodo',
        components: {
            listItemTodo: () => import('./homeTodoItem'),
        },
    })
], mWorkPlatFormHomeTodo);
export default mWorkPlatFormHomeTodo;
